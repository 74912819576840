// src/components/Statistics.js
import { useState, useEffect } from "react";
import Papa from 'papaparse';
import { Box, Typography, Button, Dialog, DialogContent, DialogTitle, LinearProgress, MenuItem, Select, FormControl, InputLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Divider, Grid, TextField } from '@mui/material';
import axios from 'axios';
import { collection, getDocs, query } from "@firebase/firestore";
import { firestore } from '../firebase';

const typeOfPlatforms = [
  "LandRover", "OUV (FordEverest)", "LUV (Agilis)", "MB240 RecceJeep", "MB240 SpikeJeep", 
  "MB290GS", "MB290 (GRS)", "MB290 (MPSTAR)", "F550 (Ambulance)", "F550 (ComdPost)", 
  "LightStrike (MK II AGL)", "LightStrike (MK II VLSS)", "LightStrike (MK II UTILITY)", 
  "3TON GEN (PSD)", "5TONGS", "5TON (BCS)", "5TON (BLS/BFI)", "5TON (PTG)", 
  "5TON (T-HUB)", "5TON (CP)", "5TON (S/T)", "5TON (MTV CRANE)", "6TONGS", 
  "6TON (BCS)", "6TON (PTG)", "6TON (FSD)", "DNCC (ETHAN)", "HMCT", "HMCT (A1TaCC)", 
  "HMCT (SHIKRA)", "HMCT (SPYDER)", "LARC V", "WheelRecoveryVehicle (WRV)", "RD", 
  "VSD", "V15", "UAV (Parrot & Css)", "LandCruiser SUV", "MiniBus", "Twin Cab"
];

const vehicleClassMapping = {
  "landrover": "Class 3",
  "ouv (fordeverest)": "Class 3",
  "luv (agilis)": "Class 3",
  "mb240 reccejeep": "Class 3",
  "mb240 spikejeep": "Class 3",
  "mb290gs": "Class 3",
  "mb290 (grs)": "Class 3", 
  "mb290 (mpstar)": "Class 3",
  "f550 (ambulance)": "Class 4", 
  "f550 (comdpost)": "Class 4", 
  "lightstrike (mk ii agl)": "Class 3", 
  "lightstrike (mk ii vlss)": "Class 3", 
  "lightstrike (mk ii utility)": "Class 3", 
  "3ton gen (psd)": "Class 4", 
  "5tongs": "Class 4", 
  "5ton (bcs)": "Class 4", 
  "5ton (bls/bfi)": "Class 4", 
  "5ton (ptg)": "Class 4", 
  "5ton (t-hub)": "Class 4", 
  "5ton (cp)": "Class 4", 
  "5ton (s/t)": "Class 4", 
  "5ton (mtv crane)": "Class 4", 
  "6tongs": "Class 4", 
  "6ton (bcs)": "Class 4", 
  "6ton (ptg)": "Class 4", 
  "6ton (fsd)": "Class 4", 
  "dncc (ethan)": "Class 4", 
  "hmct": "Class 4", 
  "hmct (a1tacc)": "Class 4", 
  "hmct (shikra)": "Class 4", 
  "hmct (spyder)": "Class 4", 
  "larc v": "Class 4", 
  "wheelrecoveryvehicle (wrv)": "Class 4", 
  "rd": "Class 4", 
  "vsd": "Class 4", 
  "v15": "Class 4", 
  "uav (parrot & css)": "Class 4", 
  "landcruiser suv": "Rental", 
  "minibus": "Rental", 
  "twin cab": "Rental"
};

const platformDisplayNameMapping = {
  "landrover": "LandRover",
  "ouv (fordeverest)": "OUV (FordEverest)",
  "luv (agilis)": "LUV (Agilis)",
  "mb240 reccejeep": "MB240 RecceJeep",
  "mb240 spikejeep": "MB240 SpikeJeep",
  "mb290gs": "MB290GS",
  "mb290 (grs)": "MB290 (GRS)", 
  "mb290 (mpstar)": "MB290 (MPSTAR)",
  "f550 (ambulance)": "F550 (Ambulance)", 
  "f550 (comdpost)": "F550 (ComdPost)", 
  "lightstrike (mk ii agl)": "LightStrike (MK II AGL)", 
  "lightstrike (mk ii vlss)": "LightStrike (MK II VLSS)", 
  "lightstrike (mk ii utility)": "LightStrike (MK II UTILITY)", 
  "3ton gen (psd)": "3TON GEN (PSD)", 
  "5tongs": "5TONGS", 
  "5ton (bcs)": "5TON (BCS)", 
  "5ton (bls/bfi)": "5TON (BLS/BFI)", 
  "5ton (ptg)": "5TON (PTG)", 
  "5ton (t-hub)": "5TON (T-HUB)", 
  "5ton (cp)": "5TON (CP)", 
  "5ton (s/t)": "5TON (S/T)", 
  "5ton (mtv crane)": "5TON (MTV CRANE)", 
  "6tongs": "6TONGS", 
  "6ton (bcs)": "6TON (BCS)", 
  "6ton (ptg)": "6TON (PTG)", 
  "6ton (fsd)": "6TON (FSD)", 
  "dncc (ethan)": "DNCC (ETHAN)", 
  "hmct": "HMCT", 
  "hmct (a1tacc)": "HMCT (A1TaCC)", 
  "hmct (shikra)": "HMCT (SHIKRA)", 
  "hmct (spyder)": "HMCT (SPYDER)", 
  "larc v": "LARC V", 
  "wheelrecoveryvehicle (wrv)": "WheelRecoveryVehicle (WRV)", 
  "rd": "RD", 
  "vsd": "VSD", 
  "v15": "V15", 
  "uav (parrot & css)": "UAV (Parrot & Css)", 
  "landcruiser suv": "LandCruiser SUV", 
  "minibus": "MiniBus", 
  "twin cab": "Twin Cab"
};

const typeOfUnits = [
  "All Units", "FSG", "ASI", "BFI", "3DIV", "9DIV", "8SAB/40 SAR", "41 SAR", "48 SAR", 
  "7 SIB", "1 GDS", "3 GD", "ADF", "ATEC", "2 SIB", "5 SIR", "SOI", 
  "ATI OPFOR", "ATI AUTC", "ITI OPFOR", "MTI MBTC 1", "ICTC 2", "11C4I/SMII", 
  "3 TPT BN", "GTC", "30 SCE", "38 SCE", "SFLS (CDO)", "RANGER", "33 CSSB", 
  "CATC", "XIA", "3 FLOT", "1516", "GBAD", "RSAF", "Others"
];

const initializeMappings = () => {
  const mappings = {};
  typeOfPlatforms.forEach(platform => {
    mappings[platform.toLowerCase()] = {};
    typeOfUnits.forEach(unit => {
      mappings[platform.toLowerCase()][unit] = { f1: 0, f2: 0, f3: 0, f4: 0 };
    });
  });
  return mappings;
};

export default function Statistics() {
  const [statistics, setStatistics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUnit, setSelectedUnit] = useState(typeOfUnits[0]);
  const [loadingPopup, setLoadingPopup] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [currentUserName, setCurrentUserName] = useState('');
  const [editFrameDialogOpen, setEditFrameDialogOpen] = useState(false);
  const [frameDates, setFrameDates] = useState({
    f1: { start: "2024-01-01", end: "2024-09-25" },
    f2: { start: "2024-09-26", end: "2024-10-15" },
    f3: { start: "2024-10-16", end: "2024-11-03" },
    f4: { start: "2024-11-04", end: "2024-12-31" },
  });

  useEffect(() => {
    fetchStatistics();
    fetchFrameDates();
    console.log(frameDates);
  }, []);

  const fetchFrameDates = async () => {
    try {
      const response = await fetch('/api/get-frame-dates');
      if (!response.ok) {
        throw new Error(`Error fetching frame dates: ${response.statusText}`);
      }
      const data = await response.json();
      console.log(data);
      
      if (
        data &&
        ["f1", "f2", "f3", "f4"].every(
          (key) =>
            data[key] &&
            typeof data[key].start === "string" &&
            typeof data[key].end === "string"
        )
      ) {
        setFrameDates(data);
      } else {
        throw new Error("Invalid data structure");
      }
    } catch (error) {
      console.error("Failed to fetch frame dates:", error.message);
    }
  };  

  const handleSaveFrameDates = async () => {
    try {
      const res = await fetch('/api/update-frame-dates', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({frameDates}),
      });
      console.log(frameDates);
      const temp = await res.json();
      console.log(JSON.stringify(temp.error));
      setEditFrameDialogOpen(false);
      handleRefresh();
      fetchStatistics();
    } catch (error) {
      console.error("Error saving frame dates:", error);
    }
  };

  const handleFrameDateChange = (frame, field, value) => {
    setFrameDates(prev => ({
      ...prev,
      [frame]: {
        ...prev[frame],
        [field]: value,
      },
    }));
    console.log(frameDates);
  };

  const classifyFrame = (date) => {
    const entryDate = new Date(date);
    if (entryDate >= new Date(frameDates.f1.start) && entryDate < new Date(frameDates.f1.end)) return 'f1';
    if (entryDate >= new Date(frameDates.f2.start) && entryDate < new Date(frameDates.f2.end)) return 'f2';
    if (entryDate >= new Date(frameDates.f3.start) && entryDate < new Date(frameDates.f3.end)) return 'f3';
    if (entryDate >= new Date(frameDates.f4.start) && entryDate < new Date(frameDates.f4.end)) return 'f4';
    return 'none';
  };

  // Fetch statistics from the API
  const fetchStatistics = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/fetch-stats');
      const parsedData = response.data.map(vehicle => ({
        ...vehicle,
        f1: JSON.parse(vehicle.f1),
        f2: JSON.parse(vehicle.f2),
        f3: JSON.parse(vehicle.f3),
        f4: JSON.parse(vehicle.f4),
      }));
      setStatistics(parsedData || []);
    } catch (error) {
      console.error("Error fetching statistics:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUsersFromFirestore = async () => {
    try {
      const q = query(collection(firestore, "users"));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => doc.data());
    } catch (error) {
      console.error("Error fetching users from Firestore:", error);
      return [];
    }
  };

  const fetchMileageRecordsFromFirestore = async (userId) => {
    try {
      const q = query(collection(firestore, "records", userId, "mileage"));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => doc.data());
    } catch (error) {
      console.error(`Error fetching mileage records for user ${userId}:`, error);
      return [];
    }
  };

  const handleRefresh = async () => {
    const vehicleMappings = initializeMappings();
    const highDistanceRecords = [];
    setLoadingPopup(true);
    setProgressValue(0);

    try {
      const users = await fetchUsersFromFirestore();

      for (let i = 0; i < users.length; i++) {
        const user = users[i];
        setCurrentUserName(user.name);
        const mileageRecords = await fetchMileageRecordsFromFirestore(user.uid);

        mileageRecords.forEach(record => {
          if (isNaN(record.detailDate)) {
            return;
          }

          const frame = classifyFrame(record.detailDate);
          if (frame !== 'none') {
            const vehicleType = record.vehPlatform.toLowerCase();
            const unit = user.unit;
  
            if (vehicleMappings[vehicleType] && vehicleMappings[vehicleType][unit]) {
              vehicleMappings[vehicleType][unit][frame] += Number(record.distance);
            }
  
            if (Number(record.distance) > 200) {
              highDistanceRecords.push({
                name: user.name,
                unit: user.unit,
                distance: record.distance
              });
            }  
          }
        });

        setProgressValue(Math.round(((i + 1) / users.length) * 100));
      }

      await axios.post('/api/update-stats', { vehicleMappings });
      fetchStatistics();

      if (highDistanceRecords.length > 0) {
        downloadHighDistanceRecordsCSV(highDistanceRecords);
      }  
    } catch (error) {
      console.error("Error updating statistics:", error);
    } finally {
      setLoadingPopup(false);
    }
  };

  const downloadHighDistanceRecordsCSV = (records) => {
    const csvData = records.map(record => ({
      Name: record.name,
      Unit: record.unit,
      Distance: record.distance
    }));

    console.log(csvData);
  
    const csv = Papa.unparse(csvData);
  
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.setAttribute('download', 'high_distance_records.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };  
  
  const filteredStatistics = statistics.map(stat => {
    const sumDistances = (frame) => {
        if (selectedUnit === "All Units") {
            return Object.values(frame).reduce((acc, distance) => acc + distance, 0);
        } else {
            return frame[selectedUnit] || 0;
        }
    };

    const filteredStat = {
        SN: stat.SN,
        type_of_platform: stat.type_of_platform,
        f1: sumDistances(stat.f1),
        f2: sumDistances(stat.f2),
        f3: sumDistances(stat.f3),
        f4: sumDistances(stat.f4)
    };

    return filteredStat;
  });

  const calculateMileageTotals = (statistics, selectedUnit) => {
    const totals = {
      class3: { f1: 0, f2: 0, f3: 0, f4: 0 },
      class4: { f1: 0, f2: 0, f3: 0, f4: 0 },
      rental: { f1: 0, f2: 0, f3: 0, f4: 0 },
      total: { f1: 0, f2: 0, f3: 0, f4: 0 }
    };
  
    statistics.forEach(stat => {
      const classType = vehicleClassMapping[stat.type_of_platform.toLowerCase()] || "Other";
      const frameTotals = {
        f1: stat.f1,
        f2: stat.f2,
        f3: stat.f3,
        f4: stat.f4
      };
      
      Object.keys(frameTotals).forEach(frame => {
        totals.total[frame] += frameTotals[frame];
        if (classType === "Class 3") {
          totals.class3[frame] += frameTotals[frame];
        } else if (classType === "Class 4") {
          totals.class4[frame] += frameTotals[frame];
        } else if (classType === "Rental") {
          totals.rental[frame] += frameTotals[frame];
        }
      });
    });
  
    return totals;
  };  

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "short" });
    const year = date.getFullYear().toString().slice(-2); 
    return `${day} ${month} ${year}`;
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Vehicle Mileage Statistics
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Unit</InputLabel>
          <Select
            value={selectedUnit}
            onChange={(e) => setSelectedUnit(e.target.value)}
            label="Unit"
          >
            {typeOfUnits.map((unit) => (
              <MenuItem key={unit} value={unit}>{unit}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button variant="contained" onClick={() => setEditFrameDialogOpen(true)}>
            Edit Frame Dates
          </Button>
          <Button variant="contained" onClick={handleRefresh}>
            Refresh Statistics
          </Button>
        </Box>
      </Box>

      {loading ? <LinearProgress /> : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em', backgroundColor: '#f0f0f0' }}>Vehicle Type</TableCell>
                <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
                  {formatDate(frameDates.f1.start)} - {formatDate(frameDates.f1.end)}
                </TableCell>
                <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
                  {formatDate(frameDates.f2.start)} - {formatDate(frameDates.f2.end)}
                </TableCell>
                <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
                  {formatDate(frameDates.f3.start)} - {formatDate(frameDates.f3.end)}
                </TableCell>
                <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
                  {formatDate(frameDates.f4.start)} - {formatDate(frameDates.f4.end)}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredStatistics.map((row) => (
                <TableRow key={row.SN}>
                  <TableCell style={{ fontWeight: 'bold' }}>{platformDisplayNameMapping[row.type_of_platform] || row.type_of_platform}</TableCell>
                  <TableCell>{row.f1}</TableCell>
                  <TableCell>{row.f2}</TableCell>
                  <TableCell>{row.f3}</TableCell>
                  <TableCell>{row.f4}</TableCell>
                </TableRow>
              ))}
              {(() => {
                const totals = calculateMileageTotals(filteredStatistics, selectedUnit);

                return (
                  <>
                    <TableRow style={{ borderTop: '2px solid black' }}>
                      <TableCell style={{ fontWeight: 'bold' }}>Class 3 Total Mileage</TableCell>
                      <TableCell>{totals.class3.f1}</TableCell>
                      <TableCell>{totals.class3.f2}</TableCell>
                      <TableCell>{totals.class3.f3}</TableCell>
                      <TableCell>{totals.class3.f4}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold' }}>Class 4 (or higher) Total Mileage</TableCell>
                      <TableCell>{totals.class4.f1}</TableCell>
                      <TableCell>{totals.class4.f2}</TableCell>
                      <TableCell>{totals.class4.f3}</TableCell>
                      <TableCell>{totals.class4.f4}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold' }}>Rental Vehicle Total Mileage</TableCell>
                      <TableCell>{totals.rental.f1}</TableCell>
                      <TableCell>{totals.rental.f2}</TableCell>
                      <TableCell>{totals.rental.f3}</TableCell>
                      <TableCell>{totals.rental.f4}</TableCell>
                    </TableRow>
                    <TableRow style={{ borderBottom: '2px solid black', borderTop: '2px solid black' }}>
                      <TableCell style={{ fontWeight: 'bold' }}>Total Mileage</TableCell>
                      <TableCell>{totals.total.f1}</TableCell>
                      <TableCell>{totals.total.f2}</TableCell>
                      <TableCell>{totals.total.f3}</TableCell>
                      <TableCell>{totals.total.f4}</TableCell>
                    </TableRow>
                  </>
                );
              })()}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog open={loadingPopup}>
        <DialogTitle>Refreshing Statistics</DialogTitle>
        <DialogContent>
          <Typography>Processing user: {currentUserName}</Typography>
          <LinearProgress variant="determinate" value={progressValue} />
        </DialogContent>
      </Dialog>

      <Dialog open={editFrameDialogOpen} onClose={() => setEditFrameDialogOpen(false)}>
        <DialogTitle>Edit Frame Dates</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {Object.keys(frameDates).map(frame => (
              <Grid container item spacing={1} key={frame} alignItems="center">
                <Grid item xs={4}>
                  <Typography>{frame.toUpperCase()}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Start Date"
                    type="date"
                    value={frameDates[frame].start}
                    onChange={(e) => handleFrameDateChange(frame, "start", e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="End Date"
                    type="date"
                    value={frameDates[frame].end}
                    onChange={(e) => handleFrameDateChange(frame, "end", e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            <Button variant="contained" sx={{ marginRight: 1 }} onClick={() => setEditFrameDialogOpen(false)}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleSaveFrameDates}>
              Save
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}